<template>
  <div>
    <!-- Vista de solo lectura -->
    <span v-show="!isEditing" v-html="content"></span>

    <!-- Editor de texto (solo para administradores) -->
    <div v-show="isAdministrator">
      <quill-editor
        v-show="isEditing"
        v-model="editableContent"
        :options="editorOptions"
       
      />

      <!-- Botones de acción -->
      <b-button
        v-if="!isEditing"
        @click="editContent"
        size="sm"
        variant="warning"
        class="mt-2"
      >
        <feather-icon icon="Edit2Icon" size="16" />
        Editar
      </b-button>

      <b-button
        v-show="isEditing"
        @click="saveContent"
        size="sm"
        variant="primary"
        class="mt-2 mr-1"
      >
        <feather-icon icon="SaveIcon" size="16" />
        Guardar
      </b-button>

      <b-button
        v-show="isEditing"
        @click="cancelEdit"
        size="sm"
        variant="secondary"
        class="mt-2"
      >
        <feather-icon icon="XIcon" size="16" />
        Cancelar
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
  BForm,
  BInputGroup,
  BFormInput,
  BFormGroup,
  BCardImg,
  BFormText,
  BCardText,
  BInputGroupPrepend,
  BTabs,
  BTab,
  BButton,
  BFormFile,
  BOverlay,
  BImg,
  BLink,
  BFormTags,
} from "bootstrap-vue";
import { quillEditor } from "vue-quill-editor";

export default {
  name: "EditableSection",
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
    BCardBody,
    BCardText,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BTabs,
    BTab,
    BButton,
    BFormGroup,
    BCardImg,
    BFormText,
    BFormFile,
    BOverlay,
    BImg,
    BLink,
    BFormTags,

    quillEditor,
  },
  props: {
    content: String, // Recibe el contenido HTML de la sección
    isAdministrator: Boolean, // Determina si se pueden hacer cambios
  },
  data() {
    return {
      isEditing: false,
      editableContent: this.content,
      editorOptions: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ font: [] }],
            ["clean"],
          ],
        },
        placeholder: "Escribe aquí...",
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      const editor = this.$refs.quillEditor?.quill; // Referencia al editor de Quill

      if (editor) {
        const observer = new MutationObserver(() => {
          editor.emitter.emit("text-change");
        });

        observer.observe(editor.root, {
          childList: true,
          subtree: true,
        });
      }
    });
  },
  methods: {
    onEditorReady(editor) {
      // Desactiva el listener de eventos DOM obsoletos
      // editor.root.removeEventListener("DOMNodeInserted", null);
      // Quill usa un MutationObserver interno. Se puede intentar deshabilitarlo.
      if (editor?.editor?.mutationObserver) {
        editor.editor.mutationObserver.disconnect();
      }
    },
    editContent() {
      this.isEditing = true;
    },
    saveContent() {
      this.$emit("update:content", this.editableContent); // Emitimos el contenido actualizado
      this.isEditing = false;
    },
    cancelEdit() {
      this.editableContent = this.content; // Restauramos el contenido original
      this.isEditing = false;
    },
  },
  watch: {
    content(newVal) {
      this.editableContent = newVal;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
    padding-left: 0;
  }
}
#quil-content
  #quill-reminder-content
  #quill-reminder-body-content
  #quill-reminder-footer-content
  #quill-body-content
  #quil-footer-content
  ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }
  + #quill-toolbar
    #quil-body-toolbar
    #quil-footer-toolbar
    #quill-reminder-toolbar
    #quil-reminder-body-toolbar
    #quil-reminder-footer-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>
