<template>
  <div>
	<b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
		<b-tabs v-model="selectedTab">
			<b-tab title="Organigrama" active>
				<upload-documents :key="'organization_chart'" type="organization_chart" />
			</b-tab>

			<b-tab title="Posiciones">
				<b-row>
					<b-col cols="12" class="float-rigth">
						<b-button v-show="isAdministrator" size="sm" @click="openModal(null)" variant="primary">
							Agregar posición
						</b-button>
					</b-col>
				</b-row>
				<div class="match-height mt-2">
					<draggable v-model="positions" tag="div" class="draggable-container cursor-move" @change="onDragEnd">
						<div v-for="(data, index) in positions" :key="data.id" class="draggable-item" v-if="!selectedCard">
							<b-card :border-variant="data.bg" :title="data.title" bg-variant="transparent" class="shadow-none">
								<b-card-text>{{ data.description }}</b-card-text>
								<b-button size="sm" @click="selectCard(data)" :variant="`${data.bg}`" class="mr-1">
									Ver más
								</b-button>
								<b-button v-show="isAdministrator" size="sm" @click="deletePosition(data.id)" :variant="`outline-danger`" class="mr-1">
									Eliminar
								</b-button>
								<b-button v-show="isAdministrator" size="sm" @click="openModal(data)" :variant="`outline-warning`">
									Editar
								</b-button>
							</b-card>
						</div>
					</draggable>
				</div>
				<b-row>
					<b-col cols="12" v-if="selectedCard">
						<b-card no-body>
							<b-card-body>
								<b-card-title>{{ selectedCard.title }}
									<b-button class="mt-1 float-right" size="sm" @click="selectedCard = null" variant="primary">Regresar</b-button>
								</b-card-title>
								<b-card-sub-title>{{selectedCard.description}}</b-card-sub-title>
							</b-card-body>
							<b-card-body>
								<app-collapse accordion type="margin">
									<app-collapse-item title="Responsabilidades">
									<editable-section
										:content="selectedCard.responsibilities"
										:isAdministrator="isAdministrator"
										@update:content="editPosition('responsibilities', $event)"
									/>
									</app-collapse-item>
									<app-collapse-item title="KPI's">
										<editable-section
											:content="selectedCard.kapis"
											:isAdministrator="isAdministrator"
											@update:content="editPosition('kapis', $event)"/>
									</app-collapse-item>
									<app-collapse-item title="¿Que hacer cuando?">
										<editable-section
											:content="selectedCard.faqs"
											:isAdministrator="isAdministrator"
											@update:content="editPosition('faqs', $event)"/>
									</app-collapse-item>
									<app-collapse-item title="Otros">
										<editable-section
											:content="selectedCard.others"
											:isAdministrator="isAdministrator"
											@update:content="editPosition('others', $event)"/>
									</app-collapse-item>

									<app-collapse-item title="Documentos">
										<upload-documents :key="'other'" type="other" />
									</app-collapse-item>

								</app-collapse>
							</b-card-body>
						</b-card>
					</b-col>
				</b-row>
			</b-tab>

			<b-tab title="Reglamento">
				<b-card>
					<editable-section
						:content="setting.app_rules"
						:isAdministrator="isAdministrator"
						@update:content="editPosition('settings', $event)"
					/>
				</b-card>
			</b-tab>

      <b-tab title="Garantías y Devoluciones">
        <b-card>
					<editable-section
						:content="setting.app_warranties_returns"
						:isAdministrator="isAdministrator"
						@update:content="editPosition('settings', $event)"
					/>
				</b-card>
      </b-tab>
		</b-tabs>
	</b-overlay>

	<!-- Modal Para editar / agregar el position -->
    <b-modal
      ref="infoModalRef"
      id="modalCreateUpdatePositions"
      :title="titleModal"
      no-close-on-backdrop
      hide-footer
      @cancel="isActiveModal = false"
      @hidden="closeModal"
      :visible="isActiveModal"
    >
      <b-overlay
        :show="loading"
        blur="2px"
        variant="transparent"
        rounded="lg"
        opacity="0.85"
      >
        <!-- BODY -->
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <b-form
            ref="formRef"
            @submit.prevent="handleSubmit(onEditPosition)"
            @reset.prevent="resetForm"
          >
            <b-row>
              <b-col cols="12">
                <b-form-group label="Titulo" label-for="titulo">
                  <validation-provider
                    #default="{ errors }"
                    name="titulo"
                    rules="required"
                  >
                    <b-form-input
                      size="sm"
                      id="titulo"
                      v-model="position.title"
                      :state="errors.length > 0 ? false : null"
                      name="titulo"
                      placeholder="Titulo de la posición"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Descripción" label-for="description">
                  <b-form-textarea
                    id="description"
                    v-model="position.description"
                    size="sm"
                    rows="2"
                    cols="30"
                    placeholder="Ingresar una descripción"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Form Actions -->
            <div class="text-right mt-2">
              <b-button
                size="sm"
                class="mr-2"
                :disabled="loading"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="closeModal"
              >
                Cancelar
              </b-button>

              <b-button
                size="sm"
                :disabled="loading"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                type="submit"
              >
                Guardar
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-overlay>
    </b-modal>

  </div>
</template>

<script>
import axios from "axios";
import store from "@/store";
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
  BForm,
  BInputGroup,
  BFormInput,
  BFormGroup,
  BCardImg,
  BFormText,
  BCardText,
  BInputGroupPrepend,
  BTabs,
  BTab,
  BButton,
  BFormFile,
  BOverlay,
  BImg,
  BLink,
  BFormTags,
  BFormTextarea,
  BTooltip,
  VBTooltip,
  VBToggle,
} from "bootstrap-vue";
import {
  ref,
  onMounted,
  computed,
  watch,
  reactive,
  defineEmits,
  onErrorCaptured,
  nextTick,
} from "@vue/composition-api";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { required, alphaNum, email, password } from "@validations";
import Ripple from "vue-ripple-directive";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import FaqQuestionAnswer from "@/@core/components/FaqQuestionAnswer.vue";
import EditableSection from "@/@core/components/EditableSection.vue";
import UploadDocuments from "@core/components/UploadDocuments.vue";
import pdf from "vue-pdf";
import { isAuthenticated } from "@/libs/authHelper";
import * as helper from "@/libs/helpers";
import Swal from "sweetalert2";
import "animate.css";
import draggable from 'vuedraggable'


export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
    BCardBody,
    BCardText,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BTabs,
    BTab,
    BButton,
    BFormGroup,
    BCardImg,
    BFormText,
    BFormFile,
    BOverlay,
    BImg,
    BLink,
    BFormTags,
    BFormTextarea,
    BTooltip,
    VBTooltip,
    VBToggle,
	draggable,

    AppCollapse,
    AppCollapseItem,
    FaqQuestionAnswer,
    EditableSection,
    UploadDocuments,

    //
    pdf,

    //
    //
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      password,
      knowledgeBaseSearchQuery: "",
      kb: [],
    };
  },
  computed: {
    filteredKB() {
      const knowledgeBaseSearchQueryLower =
        this.knowledgeBaseSearchQuery.toLowerCase();
      return this.kb.filter(
        (item) =>
          item.title.toLowerCase().includes(knowledgeBaseSearchQueryLower) ||
          item.desc.toLowerCase().includes(knowledgeBaseSearchQueryLower)
      );
    },
  },
  created() {
    this.$http.get("/kb/data/knowledge_base").then((res) => {
      this.kb = res.data;
    });
  },
  setup() {
    const loading = ref(false);   
    const selectedTab = ref(0);
    const isEditResponsibilities = ref(false);
    const titleModal = ref("Agregar posición");
    const isActiveModal = ref(false);
    const position = ref(null);
    const isAdministrator = computed(() => {
      return store.getters["auth/getUser"]
        ? store.getters["auth/getUser"].customRol.id ==
            store.getters["auth/getAdministratorRol"] ||
            store.getters["auth/getUser"].customRol.id ==
              store.getters["auth/getDirectorRol"]
        : false;
    });

    // Colores alternados
    const colors = [
      "primary",
      "secondary",
      "success",
      "danger",
      "warning",
      "info",
    ];
    const positions = ref([]);

    // Estado para almacenar el card seleccionado
    const selectedCard = ref(null);

	const setting = ref({
		app_rules: ""
	})

    // Función para seleccionar el card
    const selectCard = (card) => {
      selectedCard.value = card;
    };

	const onDragEnd = async (event) => {

		const { oldIndex, newIndex } = event;

		// Actualizar el orden en el frontend
		const movedItem = positions.value.splice(oldIndex, 1)[0];
      	positions.value.splice(newIndex, 0, movedItem);		

		// Enviar la nueva ordenación al backend
		await updateOrderInBackend();
    }

	const updateOrderInBackend = async () => {
		// Recorremos la lista actualizada y asignamos nuevos valores de `order`
	  	positions.value.forEach((item, index) => {
			item.order = index + 1; // Se asegura de que el orden sea consecutivo
	  	});

		try {
			loading.value = true;
			const { data } = await axios.post(
				`${process.env.VUE_APP_API_PRICES}/api/client/positions/update-order`,
				positions.value,
				{
					headers: {
						Authorization: `Bearer ${store.getters["auth/getTokenCloud"].access_token}`,
						Accept: "application/json",
					},
				}
			);

			if (data.success) {
				helper.success(data.message);
			} else {
				helper.danger(data.message);
			}
		}catch(e) {
			helper.handleResponseErrors(e);
		}finally {
			loading.value = false;
		}
	}

    // Computed para ordenar y alternar colores
    const sortedAndColored = computed(() => {
      return (
        positions.value
          //.sort((a, b) => a.title.localeCompare(b.title)) // Ordenar alfabéticamente
          .map((item, index) => ({
            ...item,
            bg: colors[index % colors.length], // Asignar color alternado
          }))
      );
    });

    const openModal = (data = null) => {
      if (data != null) {
			titleModal.value = "Editar posición";
			position.value = { ...data };
      } else {
        titleModal.value = "Agregar posición";
        position.value = {
			title: "",
			description: "",
		}
      }
      isActiveModal.value = true;
    };
    const closeModal = () => {
      isActiveModal.value = false;
      position.value = null;
    };

    const loginInCloud = async () => {
      const payload = {
        grant_type: process.env.VUE_APP_API_PRICES_GRANT_TYPE,
        client_id: process.env.VUE_APP_API_PRICES_CLIENT_ID,
        client_secret: process.env.VUE_APP_API_PRICES_CLIENT_SECRET,
        scope: process.env.VUE_APP_API_PRICES_SCOPE,
      };
      try {
        const { data } = await axios.post(
          `${process.env.VUE_APP_API_PRICES}/oauth/token`,
          payload,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );

        if (data) {
          store.commit("auth/SET_TOKEN_CLOUD", data);
          localStorage.setItem("token_cloud", data.access_token);
        }
      } catch (e) {
        console.log(e);
      }
    };

	const getSetting = async () => {
		try {
			loading.value = true;
			const { data } = await axios.get(`${process.env.VUE_APP_API_PRICES}/api/client/settings`,
				{
					headers: {
						Authorization: `Bearer ${store.getters["auth/getTokenCloud"].access_token}`,
						Accept: "application/json",
					},
				}
			);

			if (data.success) {
				// setting.value = data.data;
				// let settingValue = null;
				if (!_.isEmpty(data.data)) {
					const setValues = data.data.reduce((obj, item) => {
						obj[item.key] = _.startsWith(item.key, 'enable') ? (item.value != 0) : item.value
						return obj
					}, {})
					setting.value = setValues
				}
			} else {
				helper.danger(data.message);
			}
		} catch (e) {
			helper.handleResponseErrors(e);
		} finally {
			loading.value = false;
		}
	}

    const getPositions = async () => {
      try {
        loading.value = true;
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_PRICES}/api/client/positions`,
          {
            headers: {
              Authorization: `Bearer ${store.getters["auth/getTokenCloud"].access_token}`,
              Accept: "application/json",
            },
          }
        );

        if (data.success) {
          positions.value = data.data.map((item) => ({
			...item,
			bg: colors[item.order % colors.length],
		  }));
        } else {
          helper.danger(data.message);
        }
      } catch (e) {
        helper.handleResponseErrors(e);
      } finally {
        loading.value = false;
      }
    };

    const onEditPosition = async () => {
		try 
		{
			loading.value = true;
			const { data } = position.value && position.value.id ? await axios.put(
			`${process.env.VUE_APP_API_PRICES}/api/client/positions/${position.value.id}`,
			position.value,
			{
				headers: {
				Authorization: `Bearer ${store.getters["auth/getTokenCloud"].access_token}`,
				Accept: "application/json",
				},
			}
			) : await axios.post(
			`${process.env.VUE_APP_API_PRICES}/api/client/positions`,
			position.value,
			{
				headers: {
				Authorization: `Bearer ${store.getters["auth/getTokenCloud"].access_token}`,
				Accept: "application/json",
				},
			}
			);

			if (data.success) {
				if (position.value.id) {
					positions.value = positions.value.map((item) =>
						item.id === data.data.id ? data.data : item
					);
				} else {
		
					//Deberia ir al final de la coleccion
					positions.value.push(data.data);			
				}

				positions.value = positions.value.map((item, index) => ({
					...item,
					bg: colors[index % colors.length],
				}));
				helper.success(data.message);
				closeModal();
			} else {
			helper.danger(data.message);
			}
		} catch (e) {
			helper.handleResponseErrors(e);
		} finally {
			loading.value = false;
		}
    };

	const storePosition = async () => {
		if (!isAuthenticated()) {
			await loginInCloud();
		}

		try {
			loading.value = true;
			const { data } = await axios.post(
				`${process.env.VUE_APP_API_PRICES}/api/client/positions`,
				position.value,
				{
					headers: {
						Authorization: `Bearer ${store.getters["auth/getTokenCloud"].access_token}`,
						Accept: "application/json",
					},
				}
			);
		} catch (e) {
			helper.handleResponseErrors(e);
		} finally {
			loading.value = false;
		}
	};

	const updateSettingRules = async () => {
		if (!isAuthenticated()) {
			await loginInCloud();
		}

		try {
			loading.value = true;
			const { data } = await axios.post(`${process.env.VUE_APP_API_PRICES}/api/client/settings`, setting.value,
				{
					headers: {
						Authorization: `Bearer ${store.getters["auth/getTokenCloud"].access_token}`,
						Accept: "application/json",
					},
				}
			);

			if (data.success) {
				isEditResponsibilities.value = !isEditResponsibilities.value;
				if (!_.isEmpty(data.data)) {
					const setValues = data.data.reduce((obj, item) => {
						obj[item.key] = _.startsWith(item.key, 'enable') ? (item.value != 0) : item.value
						return obj
					}, {})
					setting.value = setValues
				}
				helper.success(data.message);
			} else {
				helper.danger(data.message);
			}
		} catch (e) {
			helper.handleResponseErrors(e);
		} finally {
			loading.value = false;
		}
	}

    const editPosition = async (type = "responsibilities", newValue) => {
		if (type == "settings") {
			setting.value.app_rules = newValue
			await updateSettingRules()
		} else {
			isEditResponsibilities.value = !isEditResponsibilities.value;
			selectedCard.value[type] = newValue;
			try {
				loading.value = true;
				const { data } = await axios.put(`${process.env.VUE_APP_API_PRICES}/api/client/positions/${selectedCard.value.id}`,selectedCard.value,
					{
						headers: {
						Authorization: `Bearer ${store.getters["auth/getTokenCloud"].access_token}`,
						Accept: "application/json",
						},
					}
				);
				if (data.success) {
					selectedCard.value = data.data;
					helper.success(data.message);
				} else {
					helper.danger(data.message);
				}
			} catch (e) {
				helper.handleResponseErrors(e);
			} finally {
				loading.value = false;
			}
		}
    };

    const deletePosition = async (id) => {
      Swal.fire({
        title: `¿Desea continuar?`,
        text: `No se podrá revertir esta acción.`,
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Continuar",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        showClass: {
          popup: "animate__animated animate__flipInX",
        },
        buttonsStyling: false,
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          if (!isAuthenticated()) {
            await loginInCloud();
          }

          try {
            loading.value = true;
            const { data } = await axios.delete(
              `${process.env.VUE_APP_API_PRICES}/api/client/positions/${id}`,
              {
                headers: {
                  Authorization: `Bearer ${store.getters["auth/getTokenCloud"].access_token}`,
                  Accept: "application/json",
                },
              }
            );

            if (data.success) {
              positions.value = positions.value.filter(
                (item) => item.id !== id
              );
              helper.success(data.message);
            } else {
              helper.danger(data.success);
            }
          } catch (e) {
            loading.value = false;
            helper.handleResponseErrors(e);
          } finally {
            loading.value = false;
          }
        },
        allowOutsideClick: () => !Swal.isLoading(),
      });
    };

    const handleError = (error) => {
      console.error("Error al cargar el PDF:", error);
    };

    onMounted(async () => {

    });

    watch(selectedTab, async (value) => {
      if (value === 1) {
        await getPositions();
      } else if (value === 2) {
		await getSetting();
	  }
    });

    return {
      loading,
      positions,
      position,
      selectedCard,
      selectedTab,
      isEditResponsibilities,
      titleModal,
      isActiveModal,
	  setting,

      //Computed
      sortedAndColored,
      isAdministrator,

      //Methods
	  storePosition,
	  onDragEnd,
      onEditPosition,
      openModal,
      closeModal,
      deletePosition,
      editPosition,
      selectCard,
      handleError,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-knowledge-base.scss";
</style>

<style scoped>
/* Contenedor flexible para simular las columnas de Bootstrap */
.draggable-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px; /* Espacio entre elementos */
}

/* Estilo para cada elemento arrastrable */
.draggable-item {
  flex: 1 1 calc(50% - 16px); /* md="6" (2 columnas) */
  max-width: calc(50% - 16px); /* md="6" */
  display: flex; /* Hace que los hijos (.draggable-item) tengan la misma altura */
}

/* Estilo para pantallas grandes (xl="4") */
@media (min-width: 1200px) {
  .draggable-item {
    flex: 1 1 calc(33.333% - 16px); /* xl="4" (3 columnas) */
    max-width: calc(33.333% - 16px); /* xl="4" */
  }
}

/* Asegura que las tarjetas ocupen todo el espacio disponible */
.draggable-item .card {
  flex: 1; /* Hace que la tarjeta ocupe todo el espacio del contenedor */
  display: flex;
  flex-direction: column;
}

/* Asegura que el contenido de la tarjeta ocupe el espacio restante */
.draggable-item .card-body {
  flex: 1;
}

/* Cursor de arrastre */
.cursor-move {
  cursor: move;
}
</style>