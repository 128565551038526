<template>
    <div>
        <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
            <b-card v-if="isAdministrator">
                <b-form @submit="onSubmit" @reset="onReset">
                    <b-form-group label-size="sm" label="Subir archivo" description="Archivo PDF" :label-for="`file-input${type}`">
                        <b-form-file :id="`file-input${type}`" size="sm" v-model="file" accept=".pdf" :state="Boolean(file)" 
                            placeholder="Elige un archivo o arrástralo aquí..."/>
                    </b-form-group>
                    <b-button size="sm" type="submit" variant="primary" class="mr-1">Subir</b-button>
                    <b-button size="sm" type="reset" variant="danger">Cancelar</b-button>
                </b-form>
            </b-card>

            <section id="knowledge-base-content">
                <b-row class="kb-search-content-info match-height">
                    <b-col v-for="(item, index) in documents" :key="index" md="6" sm="6" class="kb-search-content">
                        <b-card no-body class="text-center cursor-pointer">
                            <b-card-header>
                                <h4>{{ item.name }}</h4>
                                <b-button v-if="isAdministrator" size="sm" variant="danger" @click="deleteDocument(item.id)">
                                    <feather-icon icon="TrashIcon" /> Eliminar
                                </b-button>
                            </b-card-header>
                            <!-- <iframe :src="item.path" width="100%" height="970px"></iframe> -->
                            <object :data="item.path" type="application/pdf" width="100%" style="height: 100vh;"></object>
                        </b-card>
                    </b-col>
                </b-row>
            </section>
        </b-overlay>
    </div>
</template>

<script>
import axios from "axios";
import store from "@/store";
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
  BForm,
  BInputGroup,
  BFormInput,
  BFormGroup,
  BCardImg,
  BFormText,
  BCardText,
  BInputGroupPrepend,
  BTabs,
  BTab,
  BButton,
  BFormFile,
  BOverlay,
  BImg,
  BLink,
  BFormTags,
  BFormTextarea,
  BTooltip,
  VBTooltip,
  VBToggle,
} from "bootstrap-vue";
import {
  ref,
  onMounted,
  computed,
  watch,
  reactive,
  defineEmits,
  onErrorCaptured,
  nextTick,
} from "@vue/composition-api";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { required, alphaNum, email, password } from "@validations";
import Ripple from "vue-ripple-directive";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import FaqQuestionAnswer from "@/@core/components/FaqQuestionAnswer.vue";
import EditableSection from "@/@core/components/EditableSection.vue";
import pdf from "vue-pdf";
import { isAuthenticated } from "@/libs/authHelper";
import * as helper from "@/libs/helpers";
import Swal from "sweetalert2";
import "animate.css";
import draggable from 'vuedraggable'
import { props } from 'vue-prism-component';

export default {
    name: 'UploadDocuments',
    components: {
        BRow,
        BCol,
        BCard,
        BCardHeader,
        BCardTitle,
        BCardSubTitle,
        BCardBody,
        BCardText,
        BForm,
        BInputGroup,
        BInputGroupPrepend,
        BFormInput,
        BTabs,
        BTab,
        BButton,
        BFormGroup,
        BCardImg,
        BFormText,
        BFormFile,
        BOverlay,
        BImg,
        BLink,
        BFormTags,
        BFormTextarea,
        BTooltip,
        VBTooltip,
        VBToggle,
        draggable,

        AppCollapse,
        AppCollapseItem,
        FaqQuestionAnswer,
        EditableSection,
        // AppCollapseItemMargin,

        //
        pdf,

        //
        //
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        "b-tooltip": VBTooltip,
        "b-toggle": VBToggle,
        Ripple,
    },
    props: {
        type: {
            type: String,
            default: "organization_chart",
        },
    },
    data() {
        return {
            required,
            alphaNum,
            email,
            password,
        }
    },
    setup(props, { emit }) {

        const loading = ref(false);
        const isAdministrator = computed(() => {
            return store.getters["auth/getUser"]
                ? store.getters["auth/getUser"].customRol.id ==
                    store.getters["auth/getAdministratorRol"] ||
                    store.getters["auth/getUser"].customRol.id ==
                    store.getters["auth/getDirectorRol"]
                : false;
            });

        const file = ref(null);
        const documents = ref([]);

        const loginInCloud = async () => {
            const payload = {
                grant_type: process.env.VUE_APP_API_PRICES_GRANT_TYPE,
                client_id: process.env.VUE_APP_API_PRICES_CLIENT_ID,
                client_secret: process.env.VUE_APP_API_PRICES_CLIENT_SECRET,
                scope: process.env.VUE_APP_API_PRICES_SCOPE,
            };
            try {
                const { data } = await axios.post(
                `${process.env.VUE_APP_API_PRICES}/oauth/token`,
                payload,
                {
                    headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    },
                }
                );

                if (data) {
                store.commit("auth/SET_TOKEN_CLOUD", data);
                localStorage.setItem("token_cloud", data.access_token);
                }
            } catch (e) {
                console.log(e);
            }
        };

        const getDocuments = async () => {
            
            documents.value = [];
            if (!isAuthenticated()) {
                await loginInCloud();
            }

            const queryParts = [
                `type:${props.type}`,
            ];

            const query = {
                search: queryParts.join(';'),
                searchFields: 'type:=',
                searchJoin: 'and',
            };

            try {
                loading.value = true;
                const { data } = await axios.get(`${process.env.VUE_APP_API_PRICES}/api/client/documents`,
                    {
                        headers: {
                            Authorization: `Bearer ${store.getters["auth/getTokenCloud"].access_token}`,
                            Accept: "application/json",
                        },
                        params: query,
                    }
                );

                if (data.success) {
                    documents.value = data.data;
                } else {
                    helper.danger(data.message);
                }
            } catch (e) {
                helper.handleResponseErrors(e);
            } finally {
                loading.value = false;
            }
        };

        const onSubmit = async (evt) => {
            if (!isAuthenticated()) {
                await loginInCloud();
            }

            evt.preventDefault();
            const formData = new FormData();
            formData.append("file", file.value); // this.file es el archivo seleccionado
            formData.append("type", props.type);

            try {
                loading.value = true;
                const { data } = await axios.post(`${process.env.VUE_APP_API_PRICES}/api/client/documents`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${store.getters["auth/getTokenCloud"].access_token}`,
                        "Content-Type": "multipart/form-data",
                    },
                }
                );

                if (data.success) {
                    documents.value.unshift(data.data);
                    helper.success(data.message);
                    onReset();
                } else {
                    helper.danger(data.message);
                }
            } catch (error) {
                helper.handleResponseErrors(error);
            } finally {
                loading.value = false;
            }
        };

        const deleteDocument = async (id) => {
            if (!isAuthenticated()) {
                await loginInCloud();
            }

            try {
                loading.value = true;
                const { data } = await axios.delete(`${process.env.VUE_APP_API_PRICES}/api/client/documents/${id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${store.getters["auth/getTokenCloud"].access_token}`,
                            Accept: "application/json",
                        },
                    }
                );

                if (data.success) {
                    documents.value = documents.value.filter((item) => item.id !== id);
                    helper.success(data.message);
                } else {
                    helper.danger(data.message);
                }
            } catch (e) {
                helper.handleResponseErrors(e);
            } finally {
                loading.value = false;
            }
        };

        const onReset = () => {
            file.value = null;
        }

        onMounted(async () => {
            await getDocuments()
        });

        return {
            loading,
            isAdministrator,
            file,
            documents,

            //
            getDocuments,
            deleteDocument,
            onSubmit,
            onReset,


        }
        
    },
}
</script>